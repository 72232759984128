import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'select' ]

  connect() {
    if (this.hasSelectTarget) {
      new Choices(this.selectTarget, {shouldSort: false});
    }
  }

}