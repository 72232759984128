import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.vidyardEmbed
      ? this.registerProgressEvents(window.vidyardEmbed)
      : (window.onVidyardAPI = (vidyardEmbed) => this.registerProgressEvents(vidyardEmbed));
  }

  registerProgressEvents(vidyardEmbed) {
    vidyardEmbed.api.addReadyListener((data, player) => {
      vidyardEmbed.api.progressEvents((result) => {
        if (player.element.parentElement.dataset.lessonUrl) {
          this.completeLesson(result.player);
          console.log('lesson complete');
        }
      }, [1])
    })
  }

  completeLesson(player) {
    const url = player.element.parentElement.dataset.lessonUrl;
    let csrfToken = $('meta[name="csrf-token"]').attr('content');

    $.ajax({
      type: 'patch',
      url: url,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      success: function () {
        player.element.parentElement.removeAttribute("data-lesson-url");
      }
    })
  }
}